import React from 'react';
import { FaCheckCircle, FaList, FaUserGraduate, FaUserPlus } from 'react-icons/fa';

const MainHowToGetTitle = () => {
  return (
    <>
      <section id="attestati">
        <div className="container-fluid p-0 bg-orange-50">
          <div className="container mx-auto py-9 px-6 p-0 border-bottom text-primaryLabMar">
            <div className="w-full mb-6 font-bold text-center text-4xl">Come ottenere il tuo attestato in 4 step</div>

            <ul className="flex flex-col sm:flex-row gap-5 mx-auto w-full my-10">
              <li className="shadow-lg w-full sm:w-1/3 text-center p-6 py-7 bg-white rounded-xl">
                <FaList size={'38px'} className="mx-auto mb-2" />
                <div className="text-2xl font-semibold w-full text-center mb-4">Scegli il tuo corso</div>
                <p className="text-1xl font-medium w-full text-center">
                  Scegli il corso adatto alle tue esigenze lavorative
                </p>
              </li>
              <li className="shadow-lg w-full sm:w-1/3 text-center p-6 py-7 bg-white rounded-xl">
                <FaUserPlus size={'38px'} className="mx-auto mb-2" />
                <div className="text-2xl font-semibold w-full text-center mb-4">Registrati</div>
                <p className="text-1xl font-medium w-full text-center">
                  Registrati e accedi subito alla piattaforma dedicata
                </p>
              </li>
              <li className="shadow-lg w-full sm:w-1/3 text-center p-6 py-7 bg-white rounded-xl">
                <FaUserGraduate size={'38px'} className="mx-auto mb-2" />
                <div className="text-2xl font-semibold w-full text-center mb-4">Studia</div>
                <p className="text-1xl font-medium w-full text-center">
                  Studia e segui le lezioni con il nostro materiale da pc o smartphone
                </p>
              </li>
              <li className="shadow-lg w-full sm:w-1/3 text-center p-6 py-7 bg-white rounded-xl">
                <FaCheckCircle size={'38px'} className="mx-auto mb-2" />
                <div className="text-2xl font-semibold w-full text-center mb-4">Ottieni il tuo Attestato</div>
                <p className="text-1xl font-medium w-full text-center">
                  Terminate le lezioni, fai subito l'esame ed ottieni il tuo attestato digitale legalmente riconosciuto
                  in 24 ore
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainHowToGetTitle;
