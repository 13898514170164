import React, { useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
import MainFooter from '../components/MainFooter';
import MyProfile from '../components/MyProfile';

const MyProfileView = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);
  }, []);

  //--- ---

  return (
    <>
      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader currentDomain={currentDomain} />

      <div className="container-fluid p-0 bg-white min-h-96">
        <div className="container w-full mx-auto py-9 px-3 sm:px-6 p-0 border-bottom text-primaryLabMar text-md">
          <MyProfile currentDomain={currentDomain} />
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default MyProfileView;
