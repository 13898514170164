import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiXCircle } from 'react-icons/bi';
import { generaCodice } from '../../libs/utils';

function UploadBankTransfer(props) {
  //-- state
  const [isResponse, setIsResponse] = useState('');

  // -- files
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [typeFile, setTypeFile] = useState([]);
  const [isTooBig, setIsTooBig] = useState(false);
  const [uploaded, setUpladed] = useState(false);

  const btnSubmit = React.createRef();

  const { setIsShowModal, cartData } = props || '';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //--- convert file in base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //--- onSubmit of form
  const onSubmit = async (data) => {

    btnSubmit.current.disabled = true;

    data.file = [];
    data.file.push(multipleFiles);

    let params = {
      token: sessionStorage.getItem('token'),
      ext: typeFile,
      cartID: cartData?.id || 0,
      base64: data?.file[0],
      paymentTransactionID: generaCodice('st'),
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_V2_URL}/cart/uploadpayment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      if (response.ok) {
        setUpladed(true);
        setIsResponse('success');
        setTimeout(() => {
          setIsShowModal(false);
          window.location.reload();
        }, 3500);
      } else {
        setIsResponse('error');
      }

      if (btnSubmit.current) {
        btnSubmit.current.disabled = false;
      }
    } catch (error) {
      setIsResponse('error');

      if (btnSubmit.current) {
        btnSubmit.current.disabled = false;
      }
    }
  };

  //--- on input file change
  async function handleUpload(e) {
    e.preventDefault();
    //--- upload state isTooBig for remove last selected file
    setIsTooBig(false);
    const files = e.target.files[0];

    //--- verify if previous file name is unique
    let currentFile = multipleFiles.findIndex((x) => x.name === e.target.files[0].name);

    if (currentFile > -1) {
      document.getElementById('file').value = '';
      return false;
    }

    const fileSize = files.size / 1000;

    if (fileSize > 20000) {
      setIsTooBig(true);
    } else {
      const base64File = await toBase64(files);
      const obj = {
        name: files.name,
        url: base64File,
      };

      setMultipleFiles([...multipleFiles, obj]);
      setTypeFile(files.type);
      const formData = new FormData();
      formData.append('file', multipleFiles);
    }

    document.getElementById('file').value = '';
  }

  //--- on remove items uploaded
  const removeAttach = (item) => {
    const fileIndex = multipleFiles.findIndex((x) => x.name === item.name);
    if (fileIndex > -1) {
      delete multipleFiles[fileIndex];
      // multipleFiles = multipleFiles.filter(Boolean);
      setMultipleFiles(multipleFiles.filter(Boolean));
    }
  };

  //--- show form after error message
  const retryToSend = () => {
    setIsResponse('');
  };

  //--- manage limit file uploaded and return render
  const getUploadInput = () => {
    if (multipleFiles.length < 1) {
      return (
        <div className="mt-1 flex flex-col rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 bg-white">
          <label htmlFor="file">
            {isTooBig ? <span className="text-red-800">Puoi caricare al massimo 20MB</span> : null}
          </label>
          <input
            id="file"
            type="file"
            name="file1"
            multiple
            accept="image/*, .pdf, .doc, .docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            {...register('file')}
            onChange={handleUpload}
          />
          {errors.file && <p>Seleziona un file</p>}
          <div style={{ textAlign: 'left' }} className="mt-2 font-semibold">
            formato: pdf ed immagini
          </div>
        </div>
      );
    } else {
      return <div className="font-semibold text-blue-800 text-lg">Documento pronto per l'invio</div>;
    }
  };

  return (
    <div>
      {isResponse === '' ? (
        <form onSubmit={handleSubmit(onSubmit)} className="container mx-auto p-2 md:p-0 text-gray-800">
          {getUploadInput()}

          <div>
            <>
              <ul className="my-5">
                {multipleFiles.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className="flex flex-nowrap gap-3 text-md">
                        <div className="cursor-pointer" onClick={() => removeAttach(item)}>
                          <BiXCircle size={28} />
                        </div>
                        <div>{item.name}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          </div>
          {multipleFiles.length > 0 && !uploaded && (
            <>
              <div className="flex justify-center mt-2 gap-4">
                <button ref={btnSubmit} type="submit" className="py-2 px-3 text-md font-medium text-white bg-blue-800 rounded-lg">
                  Conferma
                </button>

                <button
                  type="button"
                  className="py-2 px-3 text-sm font-medium text-white bg-red-600 rounded-lg"
                  onClick={() => {
                    setIsShowModal();
                  }}
                >
                  ANNULLA
                </button>
                <p className="mt-2 hidden">Clicca su carica per caricare il documento</p>
              </div>
            </>
          )}
          {uploaded && <p className="text-green-800">Documento caricato, grazie</p>}
        </form>
      ) : (
        ''
      )}

      {isResponse === 'success' ? (
        <div className="w-full text-center bg-green-600 p-5 mb-4 rounded-xl">
          <p className="text-lg text-white">Grazie per averci inviato la ricevuta.</p>
        </div>
      ) : null}

      {isResponse === 'error' ? (
        <>
          <div className="w-full text-center bg-red-600 p-5 mb-4 rounded-xl">
            <p className="text-lg text-white">Si è verificato un errore.</p>
          </div>
          <div onClick={() => retryToSend()}>
            <button>Riprova</button>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default UploadBankTransfer;
