import React, { useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
import MainFooter from '../components/MainFooter';

const ProgrammaCorso12View = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);
  }, []);

  return (
    <>
      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader currentDomain={currentDomain} />

      <div className="container-fluid p-0 bg-white">
        <div className="container mx-auto py-9 px-6 p-0 border-bottom text-primaryLabMar text-md">
          <div className="w-full mb-4 font-bold text-left text-3xl">Il Corso</div>
          <p>
            Il corso è rivolto a coloro che lavorano nel settore alimentare e non hanno mai conseguito un attestato di
            formazione. Il corso ha una durata di 12 ore, il tempo minimo di studio previsto è di 12 ore, esso partirà
            al momento dell'iscrizione al corso prescelto.
          </p>
          <div className="w-full mb-4 mt-6 font-bold text-left text-3xl">Programma</div>

          <ul className="w-full my-4 list-disc list-inside">
            <li>rischi e pericoli alimentari: chimici, fisici, microbiologici</li>
            <li>igiene personale e corrette prassi per la prevenzione delle malattie trasmissibili con gli alimenti</li>
            <li>obblighi e responsabilità dell’operatore del settore alimentare</li>
            <li>requisiti generali in materia d’igiene</li>
            <li>principi di pulizia e disinfezione degli ambienti e delle attrezzature</li>
            <li>modalità di conservazione degli alimenti</li>
            <li>norme di buona pratica atte ad evitare la presenza di allergeni occulti negli alimenti</li>
            <li>principali norme in materia di sicurezza degli alimenti e sanzioni per le violazioni di tali norme</li>
            <li>tracciabilità e rintracciabilità degli alimenti</li>
            <li>microrganismi patogeni e meccanismi di contaminazione biologica e chimica degli alimenti</li>
            <li>
              autocontrollo, nozioni sui prerequisiti (lotta agli infestanti, smaltimento dei rifiuti, gestione dei
              sottoprodotti di origine animale, etc…)
            </li>
            <li>autocontrollo: nozioni sulle procedure basate sui principi del sistema HACCP</li>
            <li>
              nozioni di base sugli alimenti contenenti glutine, allergeni, relativa normativa e MOCA (materiali a
              contatto degli alimenti)
            </li>
          </ul>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default ProgrammaCorso12View;
