import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeView from '../views/homeView';
import { useMediaQuery } from 'react-responsive';
import FAQView from '../views/faqView';
import PrivacyPolicyView from '../views/privacyPolicyView';
import CondizioniUsoView from '../views/condizioniUsoView';
import ProgrammaCorso12View from '../views/programmaCorso12View';
import ProgrammaCorso6View from '../views/programmaCorso6View';
import RegisterView from '../views/registerView';
import MyProfileView from '../views/myProfileView';
import WrongPayment from '../components/WrongPayment';
import ThankYouPayment from '../components/ThankYouPayment';

const AppRoutes = ({ token, setToken }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1200px)',
  });

  /** global params */
  const gData = {
    mediaQuery: {
      isDesktopOrLaptop,
    },
  };

  //--- ---

  return (
    <Routes>
      <Route path="/" exact element={<HomeView gData={gData} />} />
      <Route path="/faq" exact element={<FAQView gData={gData} />} />
      <Route path="/privacy-policy" exact element={<PrivacyPolicyView gData={gData} />} />
      <Route path="/condizioni-uso" exact element={<CondizioniUsoView gData={gData} />} />
      <Route
        path="/programma-corso-haccp-alimentarista-12-ore-categoria-c"
        exact
        element={<ProgrammaCorso12View gData={gData} />}
      />
      <Route
        path="/programma-corso-haccp-alimentarista-6-ore-categoria-b"
        exact
        element={<ProgrammaCorso6View gData={gData} />}
      />
      <Route path="/registrati" exact element={<RegisterView gData={gData} />} />
      <Route path="/myprofile" exact element={<MyProfileView gData={gData} />} />
      <Route path="/accedi" exact element={<MyProfileView gData={gData} />} />
      <Route
        path="/wrongPaymentGateway/:title/:price/:registrationCode"
        exact
        element={<WrongPayment gData={gData} provider="paypal" />}
      />
      <Route
        path="/thankYouPaymentGateway/:id/:title/:price/:registrationCode/:method"
        exact
        element={<ThankYouPayment gData={gData} provider="paypal" />}
      />
    </Routes>
  );
};

export default AppRoutes;
