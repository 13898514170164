import toast from 'react-hot-toast';

export const calculateAge = (birthDate, limit = 18) => {
  const birthday = new Date(birthDate);
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
}; //calculateAge

export const scroll2Top = (top = 0) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}; //scroll2Top

export function generaCodice(prefix = '00') {
  let data = new Date();
  let codice = '';

  codice += data.getFullYear().toString().slice(-2); // Aggiunge l'anno (ultimi due cifre)
  codice += (data.getMonth() + 1).toString().padStart(2, '0'); // Aggiunge il mese
  codice += data.getDate().toString().padStart(2, '0'); // Aggiunge il giorno
  codice += data.getHours().toString().padStart(2, '0'); // Aggiunge l'ora
  codice += data.getMinutes().toString().padStart(2, '0'); // Aggiunge i minuti
  codice += data.getSeconds().toString().padStart(2, '0'); // Aggiunge i secondi
  codice += data.getMilliseconds().toString().padStart(3, '0'); // Aggiunge i millisecondi

  return prefix + '-' + codice;
}

export function add2Cart(courseCode = '', domain = '', token) {
  const obj2Send = {
    courseCode,
    domain,
    token,
  };

  try {
    fetch(`${process.env.REACT_APP_API_V2_URL}/cart/add-order`, {
      method: 'POST',
      body: JSON.stringify(obj2Send),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message === 'ok') {
          toast.success('Corso aggiunto nella Tua area riservata.');
          setTimeout(() => {
            window.location.href = '/myprofile?domain=' + domain;            
          }, 2000);
        } else {
          toast.error("Errore nell'aggiunta nella tua area riservata.");
        }
      });
  } catch (error) {
    console.log(error);
  }
} //add2Cart

export async function getBase64FromUrl(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Errore nel recupero dell'immagine");
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Errore:', error);
  }
} //getBase64FromUrl

export async function fetchImageData(url) {
  return fetch(url)
    .then((response) => response.json())
    .then((data) => ({
      logo: data.payload.logo,
      mime: data.payload.mime,
    }))
    .catch((error) => {
      console.error('Errore nel recuperare il logo', error);
      return null;
    });
} //fetchImageData
