import React, { useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
import MainFooter from '../components/MainFooter';

const CondizioniUsoView = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);
  }, []);

  return (
    <>
      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader currentDomain={currentDomain} />

      <div className="container-fluid p-0 bg-white">
        <div className="container mx-auto py-9 px-6 p-0 border-bottom text-primaryLabMar text-md">
          <div className="w-full mb-4 font-bold text-left text-3xl">SERVIZI E CONCLUSIONI DEI CONTRATTI</div>
          <p className="mb-6">
            Le presenti Condizioni Generali di Servizio disciplinano la fornitura ai clienti dei servizi offerti da
            LAB.MAR. del Dott. Calogero Cecato, con sede in Rione IV Novembre, 16 - 91019 Sciacca (AG), P.IVA
            02558010845. I contratti tra LAB.MAR. del Dott. Calogero Cecato e il cliente relativi ai singoli servizi si
            intenderanno conclusi al momento della ricezione da parte di LAB.MAR. del Dott. Calogero Cecato del
            pagamento dei corrispettivi per i servizi medesimi.
          </p>
          <div className="w-full mb-2 mt-4 font-semibold text-xl">DIRITTO DI RECESSO</div>
          <p>
            Il diritto di recesso può essere esercitato dal momento dell'acquisto fino a quindici giorni, salvo il caso
            in cui il cliente non abbia, entro tale termine, già ottenuto l'attestato acquistato, secondo quanto
            previsto dall'art 59 del codice del consumo, lettera a. Il diritto di recesso si esercita con l'invio entro
            il termine di quindici giorni di una comunicazione scritta mediante email ad assistenzalabmar@gmail.com o
            tramite PEC a calogero.cecato@biologo.onb.it. A seguito del corretto esercizio del recesso da parte del
            Cliente, LAB.MAR. del Dott. Calogero Cecato provvederà, ai sensi dell'art. 56 comma 1 Codice del Consumo,
            entro e non oltre i successivi 14 giorni, a rimborsare allo stesso i pagamenti da questi ricevuti. LAB.MAR.
            del Dott. Calogero Cecato eseguirà il rimborso utilizzando lo stesso mezzo di pagamento usato dal Cliente
            per la transazione iniziale, salvo per il Bonifico Bancario che saranno accreditate al cliente le spese
            bancaria di euro 1.20.
          </p>
          <div className="w-full mb-2 mt-4 font-semibold text-xl">CANCELLAZIONE</div>
          <p>
            Il diritto di cancellazione può essere esercitata dal momento dell'acquisto fino a quindici giorni, salvo il
            caso in cui il cliente non abbia, entro tale termine, già ottenuto l'attestato acquistato, secondo quanto
            previsto dall'art 59 del codice del consumo, lettera a. Il diritto di cancellazione si esercita con l'invio
            entro il termine di quindici giorni di una comunicazione scritta mediante email ad
            assistenzalabmar@gmail.com o tramite PEC a calogero.cecato@biologo.onb.it. LAB.MAR. del Dott. Calogero
            Cecato provvederà, ai sensi dell'art. 56 comma 1 Codice del Consumo, entro e non oltre i successivi 14
            giorni, ad applicare il diritto di cancellazione.
          </p>
          <div className="w-full mb-2 mt-4 font-semibold text-xl">PROCEDURA DI REGISTRAZIONE</div>
          <p>
            Nel completare le procedure di registrazione ai servizi, il Cliente si obbliga a seguire le indicazioni
            indicate sul sito e a fornire i propri dati personali in maniera corretta e veritiera. La procedura di
            registrazione ai servizi viene avviata autonomamente dal cliente e si svolge e conclude esclusivamente per
            via telematica. La procedura di registrazione consente al cliente di usufruire dei servizi della piattaforma
            e-learning per un periodo di 6 mesi, tuttavia come stabilito dal D.A. 275/2018 e s.m.i. i corsi per
            operatore del settore alimentare erogati in modalità FAD (formazione a distanza) dovranno concludersi entro
            e non oltre 10 giorni dall'inizio dell'attività didattica, ovvero dall'avvenuto pagamento.
            <br /> L'attivazione e l'accesso ai servizi avviene istantaneamente dopo aver ricevuto il pagamento da parte
            del cliente.
            <br />
            <br />
            Il cliente si obbliga a informare tempestivamente LAB.MAR. del Dott. Calogero Cecato di qualsiasi variazione
            dei propri dati in ogni tempo comunicati. Qualora poi il cliente comunichi dati non esatti o incompleti,
            LAB.MAR. del Dott. Calogero Cecato avrà facoltà di non attivare e/o sospendere il servizio fino a che il
            cliente non provveda a sanare tali mancanze.
            <br /> LAB.MAR. del Dott. Calogero Cecato si riserva tale facoltà anche nel caso in cui gli organi
            competenti (ad es. banche o titolari di carte di credito) contestino i pagamenti effettuati dal Cliente. In
            occasione della richiesta di attivazione di un servizio da parte del cliente, LAB.MAR. del Dott. Calogero
            Cecato attribuirà allo stesso uno User ID ed una Password. Il cliente riconosce che tali User Id e Password
            costituiscono i soli mezzi idonei ad identificare il cliente al momento dell'accesso ai servizi. Il cliente
            pertanto conviene e concorda che tutti gli atti compiuti mediante utilizzo delle menzionate user id e
            password saranno a lui attribuiti e avranno efficacia vincolante nei suoi confronti. Il cliente riconosce di
            essere il solo ed esclusivo responsabile degli atti compiuti tramite le proprie user id e password e si
            obbliga a mantenerne la segretezza e a custodirli con la dovuta cura e diligenza e a non cederli anche
            temporaneamente a terzi.
          </p>
          <div className="w-full mb-2 mt-4 font-semibold text-xl">
            REGOLAMENTO E CARATTERISTICHE DELLA PIATTAFORMA E-LEARNING
          </div>
          <p>
            La piattaforma e-learning contiene tutto ciò che è necessario per erogare la formazione degli operatori del
            settore alimentare secondo quanto stabilito dal D.A. N° 698/2022, come video lezioni, materiale didattico,
            questionari intermedi e test di apprendimento.
            <br />
            <br />
            La Piattaforma e-learning consente:
            <ul className="w-full my-4 list-decimal list-inside">
              <li className="my-4">L’accesso degli utenti tramite credenziali univoche;</li>
              <li className="my-4">La registrazione degli accessi degli utenti;</li>
              <li className="my-4">
                La temporizzazione degli accessi degli utenti, in modo da assicurare che l'utente debba ripetere il
                modulo formativo ogni qualvolta venga raddoppiato il tempo massimo previsto per la fruizione dello
                stesso;
              </li>
              <li className="my-4">Che la barra di scorrimento dei file video, non possa essere spostata in avanti;</li>
              <li className="my-4">
                Che siano previsti mini-test in itinere, in cui l'ordine delle domande a risposta multipla deve essere
                casuale per ogni login;
              </li>
              <li className="my-4">
                Che l'utente debba ripetere il modulo formativo ogni qualvolta il mini-test in itinere non venga
                superato per tre volte consecutive;
              </li>
              <li className="my-4">
                Che l'utente debba ripetere l'intero percorso di formazione, qualora l'attività formativa non sia stata
                conclusa entro 10 giorni dal suo inizio;
              </li>
              <li className="my-4">
                Di seguire i corsi di formazione per gli operatori del settore alimentare progettati secondo le
                disposizioni delle normative vigenti in materia, in termini di durata e contenuti minimi, con
                particolare riferimento al D.A. N° 698/2022;
              </li>
            </ul>
          </p>
          <div className="w-full mb-2 mt-4 font-semibold text-xl">CORRISPETTIVI E PAGAMENTI</div>
          <p>
            corrispettivi per i servizi nonché i termini e le modalità per il pagamento dei corrispettivi sono quelli
            stabiliti e disponibili sul sito. Il cliente ha la facoltà di scegliere tra i diversi strumenti di pagamento
            autorizzati da LAB.MAR. del Dott. Calogero Cecato (PayPal, Stripe, e Bonifico Bancario). Il Cliente
            garantisce si d'ora la disponibilità delle somme necessarie per effettuare i pagamenti. A seguito del
            pagamento online, LAB.MAR. del Dott. Calogero Cecato provvederà ad inviare all'indirizzo email indicato in
            fase di registrazione idonea ricevuta o fattura di pagamento.
            <br /> Tutti gli oneri fiscali derivanti dall'utilizzo dei servizi da parte del cliente saranno a carico
            esclusivo di quest'ultimo.
          </p>

          <ul className="w-full my-4 list-decimal list-inside hidden">
            <li className="my-4"></li>
          </ul>

          <div className="w-full mb-2 mt-4 font-semibold text-xl">MODIFICHE DELLE CONDIZIONI GENERALI DI SERVIZIO</div>
          <p>
            LAB.MAR. del Dott. Calogero Cecato si riserva il diritto di modificare in ogni tempo le presenti condizioni
            generali di servizio, con comunicazione via email al cliente con preavviso di almeno 30 gironi, qualora
            ricorrano i seguenti motivi:
            <ul className="w-full my-4 list-decimal list-inside">
              <li className="my-4">
                mutamento delle condizioni tecniche/economiche/contrattuali imposte da terze parti (a titolo
                esemplificativo non esaustivo, fornitori e partner commerciali) e /o conseguenti all'entrata in vigore
                di nuove disposizion i normative o regolamentari, o alla modifica di disposizioni esistenti (incluse le
                regole imposte dalle Authority dei nomi a dominio);
              </li>
              <li className="my-4">necessità di mantenere un adeguato livello di servizio;</li>
              <li className="my-4">assicurare adeguati standard di sicurezza alla piattaforma;</li>
              <li className="my-4">entrata in vigore di nuove disposizioni di legge;</li>
              <li className="my-4">oppure vi sia una modifica dei prezzi di vendita dei servizi.</li>
            </ul>
          </p>

          <div className="w-full mb-2 mt-4 font-semibold text-xl">
            UTILIZZO DEI SERVIZI E RESPONSABILITA' DEL CLIENTE
          </div>
          <p>
            Il cliente si impegna ad utilizzare i servizi con la migliore diligenza, rispettando le regole di utilizzo
            indicate nei singoli servizi ed in modo tale da non compromettere la stabilità, la sicurezza e la qualità
            dei servizi. Nei casi di particolare gravità ed urgenza LAB.MAR. del Dott. Calogero Cecato di riserva il
            diritto di sospendere o interrompere la fornitura del servizio anche senza preventiva comunicazione al
            cliente qualora rilevi, a proprio insindacabile giudizio e/o a seguito di segnalazioni di terzi, indici dai
            quali risulti che le modalità di utilizzo del Servizio da parte del cliente o da altri soggetti autorizzati
            dal cliente possano, direttamente o indirettamente, cagionare danni a LAB.MAR. del Dott. Calogero Cecato o a
            terzi nonché compromettere la stabilità, sicurezza e qualità dei servizi offerti, fatto salvo il diritto di
            LAB.MAR. del Dott. Calogero Cecato al risarcimento del danno.
            <br />
            Fermo restando quanto sopra indicato, LAB.MAR. del Dott. Calogero Cecato si riserva il diritto di sospendere
            il servizio qualora, a suo insindacabile giudizio, ritenga che il cliente compia attività che violino gli
            obblighi previsti nel presente contratto. In tal caso, il cliente, a seguito di segnalazione anche via email
            da parte di LAB.MAR. del Dott. Calogero Cecato, dovrà provvedere immediatamente ad eliminare le cause della
            contestazione od a fornire idonea documentazione provante il pieno rispetto della normativa vigente della
            attività da lui svolta.
            <br />
            Il cliente riconosce di essere il solo ed esclusivo responsabile per le attività svolte attraverso il
            servizio o a lui direttamente o indirettamente riferibili, ed in particolare di essere responsabile dei
            contenuti e le comunicazioni inserite, pubblicate, diffuse e trasmesse su o tramite i servizi. Pertanto
            LAB.MAR. del Dott. Calogero Cecato non può essere ritenuta in alcun modo responsabile per illeciti, penali,
            civili ed amministrativi commessi dal cliente per mezzo del servizio. Il cliente si obbliga a manlevare e,
            comunque, a tenere indenne LAB.MAR. del Dott. Calogero Cecato da qualsiasi azione, istanza, pretesa, costo o
            spesa, incluse le ragionevoli spese legali, eventualmente derivanti alla stessa a causa del mancato rispetto
            da parte del cliente delle obbligazioni assunte e delle garanzie prestate con l'accettazione delle presenti
            condizioni generali di servizio e comunque connesse all'utilizzo dei servizi da parte del cliente.
          </p>

          <div className="w-full mb-2 mt-4 font-semibold text-xl">Foro competente</div>
          <p>
            Il presente contratto è regolato dalle leggi della Repubblica Italiana. Per qualsiasi controversia inerente
            il presente contratto o dalla sua esecuzione sarà esclusivamente competente il Foro di Sciacca.
          </p>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default CondizioniUsoView;
