import React from 'react';
import ModalUploadBankTransfer from './ModalUploadBankTransfer';

const BankTransfer = (prop) => {
  const { cartData, userData } = prop;
  const [isShowModal, setIsShowModal] = React.useState(false);

  const toggleDialogUpload = () => {
    setIsShowModal(!isShowModal);
  };

  //--- ---

  return (
    <>
      <section className="container-fluid p-0 m-0 mt-6 mb-4">
        <div className="container mx-auto max-w-1xl p-0 border-bottom">
          <p className="text-2xl font-semibold">Come attivare il corso selezionato effettuando un bonifico bancario:</p>
          <p className="text-lg">
            Quando eseguirai il bonifico bancario per il <b>{cartData?.description}</b> allega la ricevuta. Si consiglia
            di effetuare un Bonifico Bancario ISTANTANEO. Il corso sarà attivato automaticamente dopo l'avvenuto
            pagamento.
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mx-auto w-full my-10 mt-4 mb-0">
            <div className="w-full">
              <ul className="mt-6">
                <li>
                  <b>Banca:</b> Intesa San Paolo SpA
                </li>
                <li>
                  <b>Intestato a:</b> Calogero Cecato
                </li>
                <li>
                  <b>IBAN:</b> IT42A0306983170100000008840
                </li>
                <li>
                  <b>Causale:</b> {userData.name + ' ' + userData.surname} - {cartData?.code}
                </li>
                <li className={`${(cartData?.usedBonus !== null && cartData?.usedBonus !== '') && 'line-through'}`}>
                  <b>da Pagare:</b> € {(parseFloat(cartData?.price) > 0 ? parseFloat(cartData?.price) : 0).toFixed(2)}
                </li>
                {(cartData?.usedBonus !== null && cartData?.usedBonus !== '') && (<li className="text-red-900">
                  <b>da Pagare con Promo:</b> € {(parseFloat(cartData?.priceDiscount) > 0 ? parseFloat(cartData?.priceDiscount) : 0).toFixed(2)}
                </li>)}
              </ul>
            </div>
            <div className="w-full content-center text-center">
              <button
                className="bg-blue-900 text-white font-semibold text-lg p-4 m-0 rounded-lg pt-1 pb-2 w-full h-20 sm:h-32"
                onClick={() => {
                  toggleDialogUpload();
                }}
              >
                invia ricevuta bonifico bancario
              </button>
            </div>
          </div>
        </div>
      </section>

      {isShowModal && <ModalUploadBankTransfer setIsShowModal={setIsShowModal} cartData={cartData} />}
    </>
  );
};

export default BankTransfer;
