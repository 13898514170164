import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import LoaderFullSwcreen from './LoaderFullSwcreen';
import { omitPattern, regextPattern } from '../libs/constants';
import { useTranslation } from 'react-i18next';

const LoginForm = (props) => {
  const [lastToastId, setLastToastId] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [isRecoveryPassword, setIsRecoveryPassword] = useState(false);

  const {
    register,
    //resetField,
    reset,
    handleSubmit,
    //watch,
    //setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const { t } = useTranslation();

  const handleChange = async (e, omitPattern = '') => {
    const { value, name } = e.target;

    // apply omit pattern
    const omitRegex = omitPattern;
    const newValue = value.replace(omitRegex, '');
    e.target.value = newValue;

    // Trigger validation
    await trigger(name);
  }; //handleChange;

  const onSubmitLogin = (data = '') => {
    setShowLoader(true);

    const obj2Send = { ...data };

    try {
      fetch(`${process.env.REACT_APP_API_V2_URL}/account/login?${new URLSearchParams(obj2Send).toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status !== 200) {
            setShowLoader(false);
            showToast(data.message, 'error', 5000);
            return true;
          }

          if (parseInt(data?.payload?.version) === 2) {
            sessionStorage.setItem('token', data?.payload?.token || '');
            setShowLoader(false);
            reset();
            window.location.href = '/myprofile';
          }

          if (parseInt(data?.payload?.version) < 2) {
            window.location.href = `${process.env.REACT_APP_EXT_URL}/login/` + data?.payload?.token;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }; //onSubmitLogin;

  const onSubmitRecoveryPassword = (data = '') => {
    setShowLoader(true);

    const obj2Send = { ...data };

    try {
      fetch(
        `${process.env.REACT_APP_API_V2_URL}/account/recovery-password?${new URLSearchParams(obj2Send).toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status !== 200) {
            setShowLoader(false);
            showToast(data.message, 'error', 5000);
            return true;
          }

          sessionStorage.setItem('token', data?.payload?.token || '');
          showToast("La password è stata inviata all'indirizzo indicato.", 'success', 3500);
          setShowLoader(false);
          reset();

          setTimeout(() => {
            toggleForm();
          }, 3000);
        });
    } catch (error) {
      console.log(error);
    }
  }; //onSubmitRecoveryPassword;

  const showToast = (message, mode, timer = 5000) => {
    if (lastToastId) {
      toast.dismiss(lastToastId);
    }
    let id = 0;
    if (mode === 'error') {
      id = toast.error(message, {
        duration: timer,
        style: {
          borderRadius: '10px',
          background: '#f44336',
          color: '#fff',
          padding: '12px 24px',
        },
      });
    }
    if (mode === 'success') {
      id = toast.success(message, {
        duration: timer,
        style: {
          borderRadius: '10px',
          background: '#026300',
          color: '#fff',
          padding: '12px 24px',
        },
      });
    }
    setLastToastId(id);
  }; //showToast;

  const toggleForm = () => {
    reset();
    setIsLoginForm(!isLoginForm);
    setIsRecoveryPassword(!isRecoveryPassword);
  }; //toggleForm;

  //--- ---

  return (
    <>
      {showLoader && <LoaderFullSwcreen />}

      <div className="text-xl font-semibold mt-8 mb-8">
        <div className="flex flex-col items-center justify-center px-6 mx-auto lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            {isLoginForm && (
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="flex items-center justify-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Accedi al tuo profilo personale
                </h1>

                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmitLogin)}>
                  <div className="w-full px-1 mb-2 sm:mb-4 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Email:
                    </label>
                    <input
                      {...register('email', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.email, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.email),
                      })}
                      placeholder="Email"
                      className="text-md font-normal appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.email && <span className="text-red-500 text-xs italic">{errors.email.message}</span>}
                  </div>

                  <div className="w-full px-1 mb-2 sm:mb-4 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Password:
                    </label>
                    <input
                      {...register('password', {
                        /*required: t('errors.requiredField'),
                        pattern: { value: regextPattern.password, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.password),
                        minLength: { value: 8, message: t('errors.minLength', { min: 8 }) },
                        maxLength: { value: 14, message: t('errors.maxLength', { max: 14 }) },*/
                      })}
                      placeholder="Password"
                      className="text-md font-normal appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.password && (
                      <>
                        <span className="text-red-500 text-xs italic">{errors.password.message}</span>

                        <legend className="text-gray-800 text-xs mt-2">
                          La password deve contenere:
                          <ul className="list-disc list-inside">
                            <li>Almeno 8 caratteri e al massimo 14</li>
                            <li>Almeno una lettera maiuscola</li>
                            <li>Almeno una lettera minuscola</li>
                            <li>Almeno un numero</li>
                            <li>Almeno un carattere speciale (!@#$%^&*)</li>
                          </ul>
                        </legend>
                      </>
                    )}
                  </div>

                  <button
                    type="submit"
                    className={`w-full mt-6 bg-primaryLabMar font-semibold text-md border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                  >
                    ACCEDI
                  </button>

                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => {
                        toggleForm();
                      }}
                      className="text-md font-medium text-primary-600 hover:underline dark:text-primary-500"
                    >
                      password dimenticata?
                    </button>
                  </div>
                </form>
              </div>
            )}

            {isRecoveryPassword && (
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="flex items-center justify-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Password dimenticata?
                </h1>

                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmitRecoveryPassword)}>
                  <div className="w-full px-1 mb-2 sm:mb-4 md:mb-0">
                    <label
                      className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Email:
                    </label>
                    <input
                      {...register('email', {
                        required: t('errors.requiredField'),
                        pattern: { value: regextPattern.email, message: t('errors.omitWarning') },
                        onChange: (e) => handleChange(e, omitPattern.email),
                      })}
                      placeholder="Email"
                      className="text-md font-normal appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                    />
                    {errors.email && <span className="text-red-500 text-xs italic">{errors.email.message}</span>}
                  </div>

                  <button
                    type="submit"
                    className={`w-full mt-6 bg-primaryLabMar font-semibold text-md border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}
                  >
                    AVANTI
                  </button>

                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => {
                        toggleForm();
                      }}
                      className="text-md font-medium text-primary-600 hover:underline dark:text-primary-500"
                    >
                      Vuoi accedere al tuo profilo personale?
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
