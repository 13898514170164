import React from 'react';

const Modal = (props) => {
  const { setIsShowModal, handleAction } = props;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-1000 text-white flex flex-col justify-center items-center">
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <button
              type="button"
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => {
                setIsShowModal();
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <p className="mb-4 mt-5 text-gray-700">Confermi di proseguire con l'operazione richiesta?</p>
            <div className="flex justify-center items-center space-x-4">
              <button
                type="button"
                className="py-2 px-3 text-sm font-medium text-white bg-red-600 rounded-lg"
                onClick={() => {
                  setIsShowModal();
                }}
              >
                ANNULLA
              </button>
              <button
                type="submit"
                className="py-2 px-3 text-sm font-medium text-center text-white bg-green-600 rounded-lg"
                onClick={() => {
                  handleAction();
                }}
              >
                CONFERMA
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
