import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { langApp } from './constants';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    wait: true,
    lng:
      sessionStorage.getItem('i18nStore') ||
      langApp.filter((item) => {
        return item.default === true && item.isActive === true;
      })[0].code,
    fallbackLng:
      langApp.filter((item) => {
        return item.fallBack === true && item.isActive === true;
      })[0].code || sessionStorage.getItem('i18nStore'),
    debug: false,
    detection: {
      order: ['sessionStorage'],
      cache: ['sessionStorage'],
      lookupsessionStorage: 'i18nStore',
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
