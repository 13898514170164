import React from 'react';

const ChooseCourses = (prop) => {
  //--- ---

  const { courses, currentDomain } = prop;

  return (
    <>
      <div>
        <div className="text-2xl font-semibold">Devi selezionare il corso al quale vuoi iscriverti</div>
        <ul className="w-full mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8 text-primaryLabMar">
          {courses.map((course, index) => {
            return (
              <li
                key={index}
                className="grid grid-cols-1 content-between gap-2 shadow-lg text-center p-6 py-7 bg-white rounded-xl"
              >
                {/*<div className="text-xl mb-1 font-normal text-left">{course.title}</div>*/}
                <p className="text-lg font-bold text-left mb-2 h-20">{course.description}</p>

                <div className="text-lg mb-2 font-normal text-left">{course.subTitle}</div>

                <p className="text-3xl font-normal text-left">€ {parseFloat(course.price).toFixed(2)}</p>
                <ul className="text-md leading-6 text-lg text-left">
                  <li className="mb-2 font-semibold">Incluso nel prezzo:</li>
                  <li>&#8226; Materiale didattico</li>
                  <li>&#8226; Utilizzo della piattaforma</li>
                  <li>&#8226; Tutor dedicato</li>
                  <li>&#8226; Attestato</li>
                </ul>

                {!sessionStorage.getItem('token') && (
                  <div className="my-6 flex flex-row justify-start gap-5">
                    <a
                      href={`/registrati?courseCode=${course.code}&domain=${currentDomain}`}
                      className="mt-4 w-full px-2 py-4 rounded-full font-semibold text-md bg-white text-primaryLabMar border-2 border-primaryLabMar transition-colors duration-300 hover:bg-primaryLabMar hover:text-white hover:border-transparent"
                    >
                      SELEZIONA
                    </a>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ChooseCourses;
