import React from 'react';
import { Link } from 'react-router-dom';

const MainFooter = (prop) => {
  return (
    <>
      <section id="footer">
        <div className="container-fluid p-0 bg-gray-900">
          <div className="container mx-auto py-9 px-6 p-0 border-bottom text-primaryLabMar">
            <ul className="w-full flex flex-col sm:flex-row gap-4 mb-8 text-white">
              <li className="w-full sm:w-1/2 flex flex-row gap-4 justify-between sm:justify-start">
                <a href={`#home`} onClick={(event) => prop.handleAnchorClick(event, 'home')}> Home </a>
                <a href={`./faq`}>FAQ</a>
                <a href={`./privacy-policy`} className="text-nowrap">Privacy/Policy</a>
                <a href={`./condizioni-uso`} className="text-nowrap">Condizioni d'uso</a>
              </li>
              <li className="w-full sm:w-1/2 text-center sm:text-right">
                <div className="font-semibold">Contatti ed Assistenza</div>
                <ul className="font-normal text-md">
                  <li>tel.: 0925 442972</li>
                  <li>cell. / whatsapp: 391 401 9277</li>
                  <li>email: assistenzalabmar@gmail.com </li>
                </ul>
              </li>
            </ul>

            {/** certification check button */}
            <div className="w-full mx-auto text-center sm:text-left mb-2 max-w-1xl py-1 pb-4 text-white font-semibold text-lg border-bottom border-b border-gray-600">
              <Link
                className="bg-light-blue-700 font-semibold text-sm border border-transparent text-white p-4 py-2 rounded-md transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar "
                to={`https://app.labmar.it/verify`}
              >
                Controllo Validità Certificato
              </Link>
            </div>

            {/** company info section */}
            <div className="w-full flex flex-col sm:flex-row mx-auto max-w-1xl py-1 text-center sm:text-left text-white font-semibold text-lg">
              <ul className="w-full sm:w-1/2 text-sm mb-8 sm:mb-0">
                <li>HACCP è un sito di Lab.Mar. di Cecato Calogero</li>
                <li>Rione IV Novembre, 16 - 92019 Sciacca (AG)</li>
                <li>P.IVA 02558010845</li>
              </ul>
              <ul className="w-full sm:w-1/2 text-sm text-center sm:text-right">
                <li className="font-semibold">Orari uffici:</li>
                <li>dal Lunedì al Venerdì</li>
                <li>9:00 -13:00 16:00 - 18:00</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainFooter;
