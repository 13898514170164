import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = (prop) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  let navigate = useNavigate();
  const { productId, productName, price, registrationCode, productCode } = prop;

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setIsProcessing(false);
    } else {
      //console.log('PaymentMethod ID:', paymentMethod.id);

      // Prepara i dati da inviare al backend PHP, incluso il prezzo
      const paymentData = {
        paymentMethodId: paymentMethod.id,
        productId,
        productName,
        amount: price, // Questo è il prezzo passato come prop (in centesimi, es. 1999 per €19.99)
        registrationCode,
        productCode,
        token: sessionStorage.getItem('token'),
      };

      // Invia i dati al server PHP
      const response = await fetch(`${process.env.REACT_APP_API_V2_URL}/cart/final-payment-stripe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });

      const session = await response.json();

      setError(null);
      //setIsProcessing(true);

      if (session?.payload?.status === 'success') {
        setTimeout(() => {
          navigate(
            '/thankYouPaymentGateway/' +
              paymentMethod.id +
              '/' +
              productName +
              '/' +
              price / 100 +
              '/' +
              registrationCode +
              '/stripe'
          );
        }, 1000);
      } else {
        setTimeout(() => {
          navigate('/wrongPaymentGateway/' + productName + '/' + price / 100 + '/' + registrationCode);
        }, 1000);
      }

      //alert('Pagamento effettuato con successo!');
    }
  };

  return (
    <ul className="flex flex-col md:flex-row  gap-4">
      <li className="w-full md:w-1/2">
        <form onSubmit={handleSubmit} className="stripeForm">
          <img
            src={process.env.PUBLIC_URL + '/assets/stripe_badge.png'}
            alt="Stripe form checkout"
            className="stripeFormCheckout"
          />

          <div>
            <label>Numero di Carta</label>
            <CardNumberElement className="cardElement" />
          </div>

          <div>
            <label>Data di Scadenza</label>
            <CardExpiryElement className="cardElement" />
          </div>

          <div>
            <label>CVC</label>
            <CardCvcElement className="cardElement" />
          </div>

          <input type="hidden" value={productId} name="productId" />
          <input type="hidden" value={productName} name="productName" />
          <input type="hidden" value={price} name="price" />
          <input type="hidden" value={registrationCode} name="registrationCode" />
          <input type="hidden" value={productCode} name="productCode" />

          {error && <div style={{ color: 'red' }}>{error}</div>}

          <button type="submit" disabled={!stripe || isProcessing}>
            {isProcessing ? 'Attendere...' : `Paga ora (€${parseFloat(price / 100).toFixed(2)})`}
          </button>

          <div className="text-md mt-8">
            Inserisci i dati di pagamento della tua carta per attivare il corso selezionato.
          </div>
        </form>
      </li>
      <li className="hidden md:block md:w-1/2">
        <form className="stripeForm2"></form>
      </li>
    </ul>
  );
};

export default CheckoutForm;

/*

<?php
// Chiave segreta di Stripe
$stripeSecretKey = 'sk_test_la_tua_chiave_segreta';

// Ottieni i dati inviati dal frontend
$input = file_get_contents('php://input');
$data = json_decode($input, true);

// Estrai i dati necessari
$paymentMethodId = $data['paymentMethodId'];
$productId = $data['productId'];

// Definisci il prezzo e il nome del prodotto in base al productId
switch ($productId) {
    case '12345':
        $productName = 'Corso di React Avanzato';
        $amount = 1999; // Prezzo in centesimi (es. 19.99 EUR)
        break;
    case '67890':
        $productName = 'Corso di Node.js';
        $amount = 2999; // Prezzo in centesimi (es. 29.99 EUR)
        break;
    default:
        echo json_encode(['error' => 'Prodotto non valido']);
        exit;
}

// Crea un addebito utilizzando le API di Stripe tramite cURL
$ch = curl_init('https://api.stripe.com/v1/payment_intents');
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, [
    'Authorization: Bearer ' . $stripeSecretKey,
    'Content-Type: application/x-www-form-urlencoded'
]);

// Parametri per la creazione del pagamento
curl_setopt($ch, CURLOPT_POSTFIELDS, http_build_query([
    'amount' => $amount,
    'currency' => 'eur',
    'payment_method' => $paymentMethodId,
    'confirm' => 'true',
    'description' => 'Vendita prodotto: ' . $productName . ' (ID: ' . $productId . ')'
]));

$response = curl_exec($ch);
curl_close($ch);

// Restituisci la risposta JSON al frontend
echo $response;

*/
