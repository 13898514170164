import { useEffect, useState } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import AppRoutes from './routing';

import { UserProvider } from './context/userContext';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { Toaster } from 'react-hot-toast';
//import i18next from 'i18next';
//import { langApp } from './libs/constants';
import { withTranslation } from 'react-i18next';

import 'react-whatsapp-chat-widget/index.css';
import './App.css';
import ReactCookieBot from 'react-cookiebot';

const domainGroupId = '13ada3a9-d867-4764-b903-6f1556c77cbf'; // labmar
//const domainGroupId = '21d7889d-627d-40e9-a52d-afac40f984fb'; // italiahaccp
//const domainGroupId = '44fc0397-a971-4220-b9ea-5a0c75ca8865'; // haccpformazione
//const domainGroupId = '1ef440de-6819-4229-adb6-bcb7e87aad01'; // haccpforma

function App() {
  const [useHashRouter, setUseHashRouter] = useState(false);
  const [token, setToken] = useState(false);

  const Router = useHashRouter ? HashRouter : BrowserRouter;

  useEffect(() => {
    //sessionStorage.setItem('i18nStore', 'it');
    //i18next.changeLanguage('it');
    /*i18next.changeLanguage('it').then(() => {
      console.log('Lingua cambiata con successo');
    }).catch(err => {
      console.error('Errore durante il cambio lingua:', err);
    });*/
    //--- start set default lang ---
    /*if (!sessionStorage.getItem('i18nStore')) {
      i18next.changeLanguage(
        langApp.filter((item) => {
          return item.default === true && item.isActive === true;
        })[0].code
      );
    }*/
    //eslint-disable-next-line
  }, []);

  //--- ---

  return (
    <>
      <ReactCookieBot domainGroupId={domainGroupId} />

      <UserProvider>
        <Router>
          <AppRoutes
            token={token}
            setToken={setToken}
            useHashRouter={useHashRouter}
            setUseHashRouter={setUseHashRouter}
          />
          <div>
            <Toaster />
          </div>

          <FloatingWhatsApp
            phoneNumber="3914019277"
            accountName="Labmar"
            statusMessage=""
            chatMessage="Ciao, come posso aiutarti?"
            placeholder="Scrivi un messaggio ..."
            /*avatar="images/logo.svg"*/
          />
        </Router>
      </UserProvider>
    </>
  );
}

export default withTranslation()(App);
