import React, { useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
import MainFooter from '../components/MainFooter';
import { useParams } from 'react-router-dom';

const ThankYouPayment = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();

  const { id, title, price, method } = useParams();
  const showPrice = parseFloat(price).toFixed(2).split('.');

  const go2Course = () => {
    const token = sessionStorage.getItem('token');
    sessionStorage.removeItem('token');
    window.location.href = `${process.env.REACT_APP_EXT_URL}/login/` + token;
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);

    setTimeout(() => {
      window.location.href = `/myprofile/?domain=${currentDomain}`;
    }, 8000);

    setTimeout(() => {
      go2Course();
    }, 2500);

    window.scrollTo(0, 0);
  }, []);

  //--- ---

  return (
    <>
      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader currentDomain={currentDomain} />

      <div className="container-fluid p-0 bg-white min-h-96">
        <div className="container w-full mx-auto py-9 px-3 sm:px-6 p-0 border-bottom text-primaryLabMar text-md">
          <div className="bg">
            <div className="card">
              <span className="card__success">
                <i className="ion-checkmark"></i>
              </span>

              <h1 className="card__msg">Pagamento Completato</h1>
              <h2 className="card__submsg">ti ringraziamo per aver scelto il nostro servizio.</h2>

              <div className="card__body">
                <p>{title}</p>
                <h1 className="card__price">
                  <span>€</span>
                  {showPrice[0]}
                  <span>.{showPrice[1]}</span>
                </h1>

                <p className="card__method">Metodo di Pagamento: {method}</p>
              </div>

              <div className="card__tags">
                <span className="card__tag">completato</span>
                <span className="card__tag">{method + '-' + id}</span>
              </div>

              <div className="mt-3">
                <a
                  href={`/myprofile/?domain=${currentDomain}`}
                  className="bg-teal-600 font-medium text-md border border-transparent text-white p-4 py-2 rounded-full"
                >
                  torna al profilo
                </a>

                <div className="mt-4 text-blue-gray-600 text-md">oppure attendi il reindirizzamento automatico</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default ThankYouPayment;
