import React, { useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
import MainFooter from '../components/MainFooter';

const FAQView = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);
  }, []);

  const faqs = [
    {
      title: 'A chi è rivolto il corso HACCP?',
      description: 'Il Corso HACCP è rivolto agli Operatori del settore alimentare.',
    },
    {
      title: "Per quanti anni è valido l'attestato HACCP?",
      description: 'La validità è di 3 anni dal momento del rilascio.',
    },
    {
      title: "L'attestato HACCP è valido in tutte le regioni?",
      description:
        "Si l'attestato HACCP è valido in tutte le regioni d'Italia per il principio di Reciprocità Direttiva Europea 2005/36/CE e recepita con il D.lgs 2006/2007 confermata dalla sentenza dal CONSIGLIO DI STATO del 24/11/2020 N. 07351/2020.",
    },
    {
      title: "Dopo quanto tempo ricevero' l'attestato?",
      description:
        "L'attestato sarà disponibile sul sito entro 12 ore e potrà essere scaricato, stampato ed utilizzato per le necessità lavorative, per il pagamento con Carta Prepagata, Carta di Credito/debito, Paypal mentre per il pagamento con Bonifico Bancario sarà possibile scaricarlo dal sito dopo circa 2 giorni lavorativi.",
    },
    {
      title: 'Quando potrò fare il test finale?',
      description:
        'Il test finale si sbloccherà dopo aver studiato tutte le lezioni e dopo aver raggiunto il tempo minimo di studio (varia in base alla tipologia di corso scelto e non è superiore alle 12 ore)',
    },
    {
      title: 'Quanti errori posso fare nel test finale?',
      description: 'Nel test finale il massimo degli errori concessi è 4.',
    },
    {
      title: 'Se faccio più di 4 errori cosa succede?',
      description:
        'Nel caso in cui vengano effettuati più di 4 errori e quindi il test non viene superato, potrai ripeterlo dopo 12 ore senza alcun costo aggiuntivo.',
    },
    {
      title: 'Il pagamento come e quando deve essere effettuato?',
      description:
        'Il pagamento può essere effettuato tramite bonifico bancario, carta prepagata, carta di credito/debito e PayPal, dopo aver superato il Test Finale.',
    },
    {
      title: 'Esiste un database nazionale della veridicità degli attestati HACCP?',
      description:
        "No, non esiste un database nazionale. Puoi verificare la veridicità del tuo attestato dall'apposita sezione del sito 'Verifica attestato'.",
    },
    {
      title: 'Perché non viene scritto sull’attestato la dicitura HACCP?',
      description: 'In realtà si chiama corso di formazione per il personale alimentarista e non corso HACCP.',
    },
  ];

  return (
    <>
      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader currentDomain={currentDomain} />
      <div className="container-fluid p-0 py-8 bg-orange-50">
        <ul className="w-2/3 mx-auto bg-white divide-y shadow shadow-primaryLabMar-900 rounded-xl">
          {faqs.map((faq, index) => {
            return (
              <li>
                <details className="group">
                  <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                    <svg
                      className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      ></path>
                    </svg>
                    <span className="font-semibold">{faq.title}</span>
                  </summary>

                  <article className="px-4 pb-4">
                    <p>{faq.description}</p>
                  </article>
                </details>
              </li>
            );
          })}
        </ul>
      </div>
      <MainFooter />
    </>
  );
};

export default FAQView;
