import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeForm from './StripeForm';

const stripePromise = loadStripe('pk_live_51KlYtNEISPZRM6SEIY69SJyiWppWoVhVN5dXsLx77FU1sp6sY5droN3ZFsRuHt1TeGGwbpECVIEiPc7BdyivSCcr004d9hZqxT');

/**
 * chiave di test client
 * 
 * pk_live_51KlYtNEISPZRM6SEIY69SJyiWppWoVhVN5dXsLx77FU1sp6sY5droN3ZFsRuHt1TeGGwbpECVIEiPc7BdyivSCcr004d9hZqxT
 * pk_test_51KlYtNEISPZRM6SE1GCzE1kagSFkHXD0opZ4MelWTx3PkP1wqm2wSxY6JkbY6eFdjhM1ETqidpydr302mCwBnk3D00yE0FVWYi
 * 
 * chiave di test server
 * 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
 * sk_test_51KlYtNEISPZRM6SE0aDUalokqCj8TJwCal2zUcTFjHuPFcTzySGHNqU3g77A7svjKCTWwg33yNBBeqVuVR7sNfPZ00wec5eSwu
 */

const Stripe = (props) => {
  const { cartData } = props;

  const price = ((cartData?.usedBonus === null || cartData?.usedBonus === '') ? parseFloat(cartData?.price) : parseFloat(cartData?.priceDiscount));
  const productId = cartData.id;
  const productName = cartData.title;
  const registrationCode = cartData.registrationCode;
  const productCode = cartData.code;

  return (
    <Elements stripe={stripePromise}>
      <StripeForm
        productId={productId}
        productName={productName}
        price={price*100}
        registrationCode={registrationCode}
        productCode={productCode}
      />
    </Elements>
  );
};

export default Stripe;
