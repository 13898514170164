import React from 'react';

const MainWhyChoose = () => {
  const items = [
    {
      title: 'Certificazioni di Ampio Riconoscimento',
      description: 'Attestati riconosciuti in tutta Italia ed a livello Europeo.',
    },
    {
      title: 'Corsi HACCP Validati:',
      description: 'Corsi riconosciuti in tutta Italia, con validità confermata dal CdS (Consiglio di Stato)',
    },
    {
      title: 'Supporto Personalizzato:',
      description: 'Tutor dedicato con assistenza via telefono, email, chat e whatsapp.',
    },
    {
      title: 'Esami Illimitati Gratuiti:',
      description: "Ripeti l'esame tutte le volte che vuoi gratuitamente senza nessun costo aggiuntivo.",
    },
    {
      title: 'Formazione per il Settore Alimentare:',
      description: 'Corsi per tutte le categorie di operatori del settore alimentare.',
    },
    {
      title: 'Pagamenti Sicuri',
      description:
        "Pagamento sicuro al 100% tramite PayPal, carta di credito o Bonifico Bancario. Possibilità di pagare l'attestato in 3 rate senza costi aggiuntivi",
    },
  ];

  //--- ---

  return (
    <>
      <section id="perchèsceglierci">
        <div className="container-fluid p-0 bg-primaryLabMar">
          <div className="container mx-auto py-9 px-6 p-0 border-bottom text-white">
            <div className="w-full mb-6 font-bold text-center text-4xl">Perchè sceglierci</div>
            <p className="w-full sm:w-1/2 mx-auto mb-6 font-normal text-center text-2xl">
              Da 15 anni esperti in HACCP con 5300 attestati rilasciati in tutto il territorio nazionale
            </p>

            <ul className="w-full mx-auto grid grid-cols-1 sm:grid-cols-3 gap-4 text-primaryLabMar">
              {items.map((item, index) => {
                return (
                  <li key={index} className="shadow-lg text-center p-6 py-7 bg-white rounded-xl">
                    <div className="text-xl mb-2 font-bold text-left">{item.title}</div>
                    <p className="text-lg font-normal text-left">{item.description}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainWhyChoose;
