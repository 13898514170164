import React from 'react';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MainReviews = () => {
  const reviews = [
    {
      stars: 5,
      name: 'Alessio Bruno',
      description:
        'il corso è ben strutturato, si divide in moduli contenenti ciascuno diverse slides, chiare e spiegate in maniera esaustiva. Mi sono trovata benissimo ed è stato tutto molto semplice. Lo consiglio davvero!!!',
    },
    {
      stars: 4.5,
      name: 'Barbara Renieri',
      description:
        'Le recensioni servono sempre per migliorare. Questa volta per elogiare finanche la tempestività con la quale mi hanno inviato il certificato. Grazie',
    },
    {
      stars: 5,
      name: 'Giorgia Sardelli',
      description: 'Corso intuitivo e chiaro. Decisamente ottima scelta! Grazie a tutto lo staff ed al mio tutor',
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    className: 'center',
    centerMode: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //--- ---

  return (
    <>
      <section id="recensioni">
        <div className="container-fluid p-0 bg-orange-50">
          <div className="container mx-auto py-9 px-6 p-0 border-bottom text-primaryLabMar">
            <div className="w-full mb-6 font-bold text-center text-4xl">Recensioni</div>

            <div className="slider-container">
              <Slider {...settings}>
                {reviews.map((review, index) => {
                  return (
                    <div key={index} className="text-center px-4">
                      <div className="flex flex-row justify-center items-center gap-2 mb-4 sm:mb-2">
                        {Array.from({ length: Math.floor(review.stars) }, (_, index) => {
                          return <FaStar key={index} />;
                        })}
                        {review.stars % 1 !== 0 && <FaStarHalf />}
                      </div>
                      <div className="text-lg font-bold">{review.name}</div>
                      <p className="text-lg text-left font-normal">{review.description}</p>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainReviews;
