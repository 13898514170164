import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { generaCodice } from '../../libs/utils';

const PayPal = (prop) => {
  let navigate = useNavigate();

  const { price, registrationCode, title, id, usedBonus, priceDiscount } = prop.cartData;

  //--- ---

  return (
    <div className="mt-6">
      <PayPalScriptProvider
        deferLoading={false}
        options={{
          'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
          currency: 'EUR',
        }}
      >
        <PayPalButtons
          style={{
            color: 'gold',
            height: 55,
            layout: 'horizontal',
            tagline: 'false',
            shape: 'pill',
            disableMaxWidth: true,
            label: 'pay',
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  orderID: registrationCode,
                  description: title,
                  amount: {
                    value: (usedBonus === null || usedBonus === '') ? parseFloat(price).toFixed(2) : parseFloat(priceDiscount).toFixed(2), //paypal price
                    currency: 'EUR',
                  },
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            const ret = await actions.order.capture();
            if (ret.status !== 'COMPLETED') {
              setTimeout(() => {
                navigate(
                  '/wrongPaymentGateway/' +
                    title +
                    '/' +
                    ((usedBonus === null || usedBonus === '') ? parseFloat(price).toFixed(2) : parseFloat(priceDiscount).toFixed(2)) +
                    '/' +
                    registrationCode
                );
              }, 1000);
            }

            const params = {
              token: sessionStorage.getItem('token') || '',
              item: title,
              fileName: ret.id,
              method: 'paypal',
              registrationCode: registrationCode,
              cartID: id,
              paymentTransactionID: generaCodice('st'),
            };

            try {
              fetch(`${process.env.REACT_APP_API_V2_URL}/cart/final-payment`, {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                  if (data?.payload?.status === 'success') {
                    setTimeout(() => {
                      navigate(
                        '/thankYouPaymentGateway/' +
                          ret.id +
                          '/' +
                          title +
                          '/' +
                          ((usedBonus === null || usedBonus === '') ? parseFloat(price).toFixed(2) : parseFloat(priceDiscount).toFixed(2)) +
                          '/' +
                          registrationCode +
                          '/' +
                          params.method
                      );
                    }, 1000);
                  } else {
                    setTimeout(() => {
                      navigate(
                        '/wrongPaymentGateway/' +
                          title +
                          '/' +
                          ((usedBonus === null || usedBonus === '') ? parseFloat(price).toFixed(2) : parseFloat(priceDiscount).toFixed(2)) +
                          '/' +
                          registrationCode
                      );
                    }, 1000);
                  }
                });
            } catch (error) {
              console.log(error);
              setTimeout(() => {
                navigate(
                  '/wrongPaymentGateway/' +
                    title +
                    '/' +
                    ((usedBonus === null || usedBonus === '') ? parseFloat(price).toFixed(2) : parseFloat(priceDiscount).toFixed(2)) +
                    '/' +
                    registrationCode
                );
              }, 1000);
            }
          }}
          onError={(err) => {
            navigate(
              '/wrongPaymentGateway/' +
                title +
                '/' +
                ((usedBonus === null || usedBonus === '') ? parseFloat(price).toFixed(2) : parseFloat(priceDiscount).toFixed(2)) +
                '/' +
                registrationCode
            );
          }}
          onCancel={() => {
            navigate(
              '/wrongPaymentGateway/' +
                title +
                '/' +
                ((usedBonus === null || usedBonus === '') ? parseFloat(price).toFixed(2) : parseFloat(priceDiscount).toFixed(2)) +
                '/' +
                registrationCode
            );
          }}
        />
      </PayPalScriptProvider>
      <div className="w-full font-medium text-md text-center">il modo più rapido e sicuro per i pagamenti online.</div>
    </div>
  );
};

export default PayPal;
