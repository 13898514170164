import moment from 'moment';
import React from 'react';

const ProfileData = (prop) => {
  const { profileData } = prop; 

  const nameSurname = profileData?.userData?.name + ' ' + profileData?.userData?.surname;
  const dateOfBirth = moment(profileData?.userData?.dateOfBirth || '').format('DD/MM/YYYY');
  const birthCity = profileData?.userData?.birthCity || '';
  const birthProvince = profileData?.userData?.birthProvince || '';
  const residenceAddress = profileData?.userData?.residenceAddress || '';
  const residenceHouseNumber = profileData?.userData?.residenceHouseNumber || '';
  const residenceCity = profileData?.userData?.residenceCity || '';
  const residenceProvince = profileData?.userData?.residenceProvince || '';
  const phoneMobile = profileData?.userData?.phoneMobile || '';
  const residenceZipCode = profileData?.userData?.residenceZipCode || '';

  const taxID = profileData?.userData?.taxID || '';
  const fiscalCode = profileData?.userData?.fiscalCode || '';
  const companyName = profileData?.userData?.companyName || '';
  const pec = profileData?.userData?.pec || '';
  const uniqueCodeBilling = profileData?.userData?.uniqueCodeBilling || '';
  const address = profileData?.userData?.address || '';
  const houseNumber = profileData?.userData?.houseNumber || '';
  const city = profileData?.userData?.city || '';
  const province = profileData?.userData?.province || '';
  const zipcode = profileData?.userData?.zipcode || '';
  
  const fiscalCodeCompany = profileData?.userData?.fiscalCodeCompany || '';

  if(typeof profileData === 'undefined') {return null;}

  //--- ---
  return (
    <>
      <section className="container-fluid p-0 m-0">
        <div className="container mx-auto max-w-1xl p-0 border-bottom">
          <div className="flex flex-col gap-6 sm:flex-row pb-6 mb-6 border-b border-gray-400">
            <div className="w-full sm:w-1/2 px-5 py-8 border border-gray-300 rounded-xl">
              <h3 className="text-xl font-semibold mb-2">Dati personali:</h3>
              <ul>
                <li>
                  Nome e Cognome: <b>{nameSurname}</b>
                </li>
                <li>
                  Nato/a il:{' '}
                  <b>
                    {dateOfBirth} a {birthCity} ({birthProvince.toUpperCase()})
                  </b>
                </li>
                <li>
                  Residente in:{' '}
                  <b>
                    {residenceAddress}, {residenceHouseNumber} - {residenceCity} ({residenceProvince.toUpperCase()}){' '}
                    {residenceZipCode}
                  </b>
                </li>
                <li>
                  Telefono: <b>{phoneMobile}</b>
                </li>
              </ul>
            </div>

            <div className="w-full sm:w-1/2 px-5 py-8 border border-gray-300 rounded-xl sm:mt-0">
              <h3 className="text-xl font-semibold mb-2">Dati di fatturazione:</h3>
              {taxID !== '' && (
                <ul>
                  <li>
                    Ragione Sociale: <b>{companyName}</b>
                  </li>
                  <li>
                    P.IVA: <b>{taxID}</b>
                  </li>
                  <li>
                    Codice Fiscale: <b>{fiscalCodeCompany}</b>
                  </li>
                  <li>
                    PEC: <b>{pec}</b>
                  </li>
                  <li>
                    Codice univoco: <b>{uniqueCodeBilling}</b>
                  </li>
                  <li>
                    Residente in:{' '}
                    <b>
                      {address}, {houseNumber} - {city} ({province.toUpperCase()}) {zipcode}
                    </b>
                  </li>
                </ul>
              )}

              {taxID === '' && (
                <ul>
                  <li>
                    Nome e Cognome: <b>{nameSurname}</b>
                  </li>
                  <li>
                    Nato/a il:{' '}
                    <b>
                      {dateOfBirth} a {birthCity} ({birthProvince.toUpperCase()})
                    </b>
                  </li>
                  <li>
                    Residente in:{' '}
                    <b>
                      {residenceAddress}, {residenceHouseNumber} - {residenceCity} ({residenceProvince.toUpperCase()})
                    </b>
                  </li>
                  <li>
                    Codice Fiscale: <b>{fiscalCode}</b>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileData;
