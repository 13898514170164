import React from 'react';

const MainHeroShort = (prop) => {
  return (
    <>
      <section className="container-fluid p-0 m-0">
        <div className="container mx-auto max-w-1xl p-0 border-bottom">
          <div className="flex items-center justify-center bg-hero">
            <div className="flex flex-col-reverse sm:flex-row gap-6 items-center max-w-8xl">
              <div className="w-full px-6 py-5 sm:px-12 sm:py-10">
                <h2 className="text-2xl sm:text-4xl text-center text-primaryLabMar font-bold leading-tight">
                  Corso HACCP Online Valido e Riconosciuto
                </h2>
                <h3 className="mt-2 sm:mt-6 text-md sm:text-xl text-left text-primaryLabMar">
                  Corso organizzato da LABMAR, Ente di formazione accreditato e Iscritto nel registro regionale per la
                  formazione degli alimentaristi al n.ro 2021/356
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainHeroShort;
